/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

const { LoadPocketButton } = require("./src/utils/pocket-button-script")

exports.shouldUpdateScroll = ({ pathname }: { pathname: string }) => {
  if (pathname.includes("-more-")) return false

  return true
}

exports.onRouteUpdate = () => {
  // @ts-ignore
  if (window.LineIt) window.LineIt.loadButton()

  // @ts-ignore
  if (window.FB) {
    // @ts-ignore
    window.FB.init({
      xfbml: true,
      version: "v20.0",
    })
  }

  LoadPocketButton()
}
